/* -------------------------------------- */
/*          CONFIGURACION GENERAL         */
/* -------------------------------------- */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

:root {
    --primary-color: #001c41;
    --secundary-color: rgb(0, 231, 231);
    --background-color: #f9f5f0;
    --dark-color: #151515;
}

html {
    font-size: 62.5%;
    font-family: 'Quicksand', sans-serif;
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




/* -------------------------------------- */
/*               UTILIDADES               */
/* -------------------------------------- */

.container {
    max-width: 120rem;
    margin: 0 auto;
}

.heading-1 {
    text-align: center;
    font-weight: 400;
    font-size: 4rem;
    position: relative;
}

.heading-1 span {
    font-weight: bold;
}

.heading-1::after {
    content: '';
    width: 6.5rem;
    height: 3px;
    background-color: #1359b6;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate( -50%, 50% );
}


/* ---------------------------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------------------------- */


/* -------------------------------------- */
/*                 BANNER                 */
/* -------------------------------------- */

.container-banner {
    position: relative;
    height: 100vh;
    margin-top: 0rem; /* Ajusta según sea necesario */
    overflow: hidden;
  }
  
  .content-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: start;
    padding: 2rem;
    padding-left: 20rem;
    
  }
  
  .content-banner.active {
    opacity: 1;
  }
  
  .content-banner h1 {
    font-size: 6.5rem;
    color: #fff;
    font-weight: 700;
    margin-bottom: 1rem;
    text-shadow: 2.5px 2.5px 4.5px rgba(0, 0, 0, 2);
  }
  
  .content-banner p {
    line-height: 3rem;
    color: #ffffffc0;
    font-size: 3rem;
    margin-top: 2rem;
    font-weight: 500;
    text-shadow: 2.5px 2.5px 4.5px rgba(0, 0, 0, 1);
    line-height: 4rem;
  }

    
  .content-banner span {
    margin-top: 4rem;

  }
  
  .content-banner span .link {
    text-decoration: none;
    color: #fff;
    background-color: #1359b6;
    display: flex;
    padding: 1rem 3rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3rem;
    cursor: pointer;
    transition: all .1s ease;
  }
  
  .content-banner span .link:hover {
    background-color: #fff;
    color: var(--dark-color);
  }


/* -------------------------------------- */
/*                COUNTER                 */
/* -------------------------------------- */

.contadores-principal {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 4.5rem;
}

.contador {
    width: 30%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}


.contador_cantidad {
    color: #114a96;
    font-size: 5.5rem;
    margin: 1rem 0;
    font-weight: 1000;
}

.contador h2 {
    font-size: 2.5rem;
}

.contador .fa-facebook-square {
    color: rgb( 66, 103, 178 );
}

.contador .fa-youtube {
    color: red;
}

.ocultar {
    opacity: 0;
}

.animar {
    animation: aparecer 1.3s;
}

@keyframes aparecer {
    0% {
        opacity: 0;
        transform: translateY( 10rem );
    }

    100% {
        opacity: 1;
        transform: translateY( 0 );
    }
}  




/* -------------------------------------- */
/*             QUIENES SOMOS              */
/* -------------------------------------- */

.quienes-somos {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    padding-top: 9rem; /* Ajusta según la altura de tu navbar */
    margin-top: -9rem;
}

.quienes-somos ul {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.quienes-somos ul li {
    list-style: none;
    margin: 0rem 2rem;
    margin-top: 2rem;
}

.quienes-somos ul li img {
    width: 10rem;
    border-radius: 100%;
    object-fit: cover;
}

.quienes-somos-text {
    display: flex;
    margin: 1rem 15rem;
}

.texto-siguiente {
    display: flex;
    margin: 1rem 1rem;
}

.img-agregada {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 15rem;
    margin-top: 0rem;
}

.img-agregada img {
    width: 100%;
    height: 30rem;
    object-fit: cover;
}

.texto-siguiente p {
    font-size: 2rem;
    font-weight: 300;
    margin: 0 14rem;
}

.quienes-somos-text img {
    width: 35rem;
    height: 30rem;
    margin-right: 4rem;
    border-radius: 3%;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);

}

.quienes-somos-text p {
    font-size: 2rem;
    font-weight: 300;
}

.quienes-somos-text.servicios .imagenes2 {
    display: flex;
    flex-direction: column;
}

.quienes-somos-text .imagenes2 img {
    margin-right: 0px;
}

.quienes-somos-text.servicios .imagenes2 img {
    width: 30rem;
    height: 18rem;
    margin-bottom: 2rem;
}

.quienes-somos-text.servicios .imagenes2 .start {
    object-position: top;
}

.servicio-responsive {
    display: none;
}


/* -------------------------------------- */
/*           NUESTROS SERVICIOS           */
/* -------------------------------------- */

.servicios-page {
    align-items: center;
    justify-content: center;
    align-items: center;

}

.servicios-page img {
    width: 2rem;
}

.main-nuestros-servicios {
    background-color: #000042;
}

.nuestros-servicios {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 4rem;
    padding-top: 3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;

}

.nuestros-servicios h1 {
    color: #f9f5f0;
    margin-bottom: 2rem;
}

.container-gallery {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat( 4, 1fr);
    grid-template-rows: repeat(2, 32rem);
    gap: 2.5rem;
    padding-top: 20rem;
    margin-top: -20rem;
}

.card-servicio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.gallery-img-1 {
    background-image: linear-gradient( #00000055, #00000055 ), url('https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720761473/0_crds1h.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gallery-img-2 {
    background-image: linear-gradient( #00000055, #00000055 ), url('https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720761559/0_voirlt.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gallery-img-3 {
    background-image: linear-gradient( #00000055, #00000055 ), url('https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720762454/0_orlvmp.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gallery-img-4 {
    background-image: linear-gradient( #00000055, #00000055 ), url('https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720762297/0_qyey4m.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gallery-img-5 {
    grid-row: 1/2;
    grid-column: 2/4;
    background-image: linear-gradient( #00000055, #00000055 ), url('https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720762679/0_ckorse.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gallery-img-6 {
    grid-row: 2/2;
    grid-column: 2/4;
    background-image: linear-gradient( #00000055, #00000055 ), url('https://res.cloudinary.com/dy1o5vcd8/image/upload/v1720762026/0_cg7ttu.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-servicio .textServicio {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    margin-top: 1.5rem;
    padding: 0rem 3rem;
    position: relative;
}

.card-servicio .ver-mas {
    display: flex;
    margin: 2rem 0rem;
    font-weight: 800;
    font-size: 1.2rem;
    padding: 1rem 3rem;
    background-color: #1359b6;
    color: #f9f5f0;
    border-radius: 2rem;
    cursor: pointer;
    transition: ease .4s all;
}

.card-servicio .ver-mas:hover {
    background-color: #f9f5f0;
    color: var(--dark-color);
}

.card-servicio .card-title {
    color: #fff;
    font-size: 3rem;
    font-weight: 800;
    position: relative;
    text-shadow: 2.5px 2.5px 4.5px rgba(0, 0, 0, 1);
}

.card-servicio .card-title::after {
    content: '';
    width: 15rem;
    height: 2.5px;
    background-color: #fff;
    position: absolute;
    bottom: -.8rem;
    left: 50%;
    transform: translate( -50%, 50% );
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.card-servicio p::after {
    content: '';
    width: 19rem;
    height: 2px;
    background-color: rgb(199, 199, 199);
    position: absolute;
    bottom: -.5rem;
    left: 50%;
    transform: translate( -50%, 50% );

}

.gallery-img-6 .card-title::after {    
    width: 35rem;
}

.gallery-img-6 p::after {
    width: 30rem;
    bottom: -.5rem;
}

.gallery-img-5 .card-title::after {    
    width: 23rem;
}

.gallery-img-5 p::after {
    width: 30rem;
    bottom: -.5rem;
}

.card-servicio a {
    text-decoration: none;
}

.quienes-somos-text.servicios {
    line-height: 3.5rem;
    margin-top: 1rem;
}

.quienes-somos-text.servicios img {
    margin: 0px;
}

.quienes-somos-text.servicios-page {
    display: flex;
    text-align: center;
    justify-content: center;
    text-align: center;
    margin: 0rem 10rem;
    padding: 0;
    margin-bottom: 2rem;
}

.quienes-somos-text.servicios-page img {
    width: 30rem;
    height: 18rem;
    margin: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    object-fit: cover;
    justify-content: center;
    align-items: center;
}

.quienes-somos-text.servico {
    line-height: 3rem;
}


.quienes-somos-text.servicios p span {
    font-weight: bold;
}

.quienes-somos-text.servicios p {
    margin-bottom: 2rem;
}

.servicios-enlace {
    margin-top: 2rem;
}

.quienes-somos-text.servicios {
    margin: 0rem 13.5rem;
}

.quienes-somos-text.servicios p {
    margin-left: 1.5rem;
    width: 600rem;
    font-weight: 350;
}

.quienes-somos-text.servicios p .texto-adicional {
    display: flex;
    font-size: 2rem;
    font-weight: 350;
    margin-left: 1.2rem;
    margin-top: -1rem;
    margin-bottom: -2.8rem;
}

/* -------------------------------------- */
/*         PROYECTOS REALIZADOS           */
/* -------------------------------------- */

.proyectos-realizados {
    padding-top: 11rem;
    margin-top: -11rem;
}

.content-proyectos-realizados {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.linear {
    margin-bottom: 4rem;
} 

.content-proyectos-realizados .card {
    width: 70%;
    margin: 2rem;
    border-radius: 6px;
    overflow: hidden;
    background-color: #f9f5f0;
    box-shadow: 0px 1px 10px rgb(0, 0, 0, 0.2);
    cursor: default;
    transition: all 400ms ease;
}

.content-proyectos-realizados .card:hover {
    box-shadow: 5px 5px 20px rgb(0, 0, 0, 0.4);
    transform: translateY(-3%);
}

.content-proyectos-realizados .card img {
    width: 100%;
    height: 250px;
    cursor: pointer;
    transition: ease .4s all;
}

.card figure {
    position: relative;
}

.card figure img {
    transition: all 0.3s ease;
    z-index: -1;
}

.barra-antipanico {
    image-rendering: -webkit-optimize-contrast; /* Mejora la calidad de la imagen en WebKit (Safari, Chrome) */
    image-rendering: -moz-crisp-edges;          /* Mejora la calidad de la imagen en Firefox */
    image-rendering: -o-crisp-edges;            /* Mejora la calidad de la imagen en Opera */
    image-rendering: crisp-edges; 
}

.card figure:hover img {
    filter: brightness(50%);
}

.card figure::before {
    content: '\f06e';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.card figure:hover::before {
    opacity: 1;
    z-index: 1;
}

.content-proyectos-realizados .card .contenido {
    padding: 1.5rem;
    text-align: center;
}

.content-proyectos-realizados .card .contenido p {
    font-size: 1.5rem;
    line-height: 1.5;
    color: #6a6a6a;
    margin-bottom: 1rem;
}

.content-proyectos-realizados .card .contenido h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.content-proyectos-realizados .card .contenido a {
    text-decoration: none;
    display: inline-block;
    padding: 1rem 2rem;
    margin-top: 1rem;
    background-color: #1359b6;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 20px;
    color: #fff;
    transition: all .4s ease;
}

.content-proyectos-realizados .card .contenido a:hover {
    color: #1359b6;
    background-color: #fff;
}




/* -------------------------------------- */
/*                GALERIA                 */
/* -------------------------------------- */

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
}
  
.image-grid img {
    width: 100%;
    cursor: pointer;
}
  
.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 3;
}
  
.lightbox img {
    max-width: 1600rem;
    max-height: 50rem;
    object-fit: cover;
}
  
.close, .prev, .next {
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: none;
    border: none;
    color: white;
    font-size: 3rem;
    cursor: pointer;
    padding: .5rem 1.1rem;
    border-radius: 50%;
    transition: all .5s ease;
}

.close {
    top: 5px;
    right: 10px;
    transition: all .5s ease;
    padding: .5rem 1rem;
    padding-bottom: .3rem;

}
    
.close:hover {
    background-color: red;
}
    
.prev {
    left: 20px;
    transition: all .5s ease;
    padding: .5rem 1.3rem;
    padding-top: .7rem;
}

.prev:hover {
    background-color: #1359B6;
}
  
.next {
    right: 20px;
    transition: all .5s ease;
    padding: .5rem 1.3rem;
    padding-top: .7rem;
}

.next:hover {
    background-color: #1359B6;
}
  
.thumbnail-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
}

.thumbnail-grid img {
    object-fit: fill;
}
  
.thumbnail {
    width: 60px;
    height: 60px;
    margin: 0 5px;
    cursor: pointer;
    opacity: 0.6;
    border: 2px solid transparent;
}
  
.thumbnail.selected {
    border: 2px solid white;
    opacity: 1;
}

.deleted {
    display: none;
    border-radius: 100%;
    border: 2px solid #fff;
    background-color: #fff;
    width: 4.5rem;
    height: 4.5rem;
}




/* -------------------------------------- */
/*               CONFIARON                */
/* -------------------------------------- */

.container-confiaron {
    padding-top: 11rem;
    margin-top: -11rem;
}

.container-confiaron ul .swiper {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 6rem 12rem;
    padding-left: 5rem;
    padding-right: 5rem;
    z-index: 0;
}

.container-confiaron ul li {
    padding-bottom: 6rem;
    cursor: grab;
}

.container-confiaron ul li img {
    width: 10rem;
    height: 10rem;
}




/* -------------------------------------- */
/*               CONTACTO                 */
/* -------------------------------------- */

.formulario-contacto {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem 0;
    margin-top: 11rem;
}

.formulario-contacto.no-active{
    display: none;
} 

.content-formulario {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat( 2, 1fr);
    margin: 3rem 15rem;
    margin-top: 2rem;
}

.formulario {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f0f4f7;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.formulario h3 {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 600;
    color: #000069;
    padding-left: 4px;
    border-left: 5px solid #1359b6;
}

.formulario input {
    outline: none;
    margin: 1rem .5rem;
    font-size: 1.8rem;
    padding: 1.2rem;
    border-radius: 0.5rem;
    border: 1px solid #C2C2C2;
    color: #3d3d3d;
    font-weight: 450;
    font-family: inherit;
}

.formulario input::placeholder {
    color: #a5a5a5;
}

.input-asunto input {
    width: 49.5rem;
}

.formulario textarea {
    outline: none;
    margin: 2rem 0.5rem;
    font-size: 1.8rem;
    padding: 1.2rem;
    border-radius: 0.5rem;
    border: 1px solid #C2C2C2;
    color: #3d3d3d;
    font-weight: 450;
    font-family: inherit;
    width: 49.5rem;
    max-width: 49.5rem;
    min-width: 49.5rem;
    max-height: 20rem;
    min-height: 20rem;
}

.formulario textarea::placeholder {
    color: #a5a5a5;
}

.formulario button {
    border: none;
    font-size: 1.6rem;
    font-weight: 500;
    background-color: #1359b6;
    padding: .8rem 2rem;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: ease .4s all;
}

.formulario button:hover {
    background-color: #000069;
}

.formulario-redes-sociales {
    padding: 2rem;
    background-color: #f0f4f7;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
}

.formulario-redes-sociales h3 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 2rem;
    position: relative;
}

.formulario-redes-sociales h3::after {
    content: '';
    width: 3rem;
    height: 3px;
    background-color: #1350a0;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate( -50%, 50% );
}

.menu-redes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.menu-redes span,
.menu-redes p {
    font-size: 18px;
    color: #555;
    display: flex;
    align-items: center;
    gap: 8px;
}

.menu-redes i {
    font-size: 20px;
    color: #1359b6;
}

.menu-redes ul {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}

.menu-redes ul li {
    list-style: none;
}

.menu-redes ul li a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #1359b6;
    color: #fff;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.menu-redes ul li a:hover {
    background-color: #000069;
}

.menu-redes ul li a i {
    font-size: 20px;
    color: #fff;
}

.mensaje-enviado {
    margin-top: 13.5rem;
    margin-bottom: 5rem;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.mensaje-enviado.active {
    display: flex;
}

.content-mensaje {
    padding: 2rem;
    background-color: #f0f4f7;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
}

.content-mensaje h3 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}

.content-mensaje h3::after {
    content: '';
    width: 3rem;
    height: 3px;
    background-color: #1350a0;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate( -50%, 50% );
}

.content-mensaje p {
    font-size: 19px;
    color: #555;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
    line-height: 3.5rem;
}

.content-mensaje ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 4rem;
}

.content-mensaje ul li {
    list-style: none;
}

.content-mensaje ul li a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #1359b6;
    color: #fff;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.content-mensaje ul li a:hover {
    background-color: #000069;
}

.content-mensaje ul li a i {
    font-size: 20px;
    color: #fff;
}

.content-mensaje span a {
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 500;
    background-color: #1359b6;
    padding: .8rem 2rem;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: ease .4s all;
}

.content-mensaje span a:hover {
    background-color: #000069;
}

.direccion {
    margin-bottom: .8rem;
}

.container-soporte {
    display: flex;
    margin: 0rem 15rem;
    margin-right: 17rem;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f0f4f7;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
}

.container-soporte p {
    font-size: 1.8rem;
    font-weight: bold;
    color: #000063;
}

.menu-galeria {
    display: none;
}

/* ---------------------------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------------------------- */
