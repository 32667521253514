
/* -------------------------------------- */
/*                 NAVBAR                 */
/* -------------------------------------- */

.container-navbar {
    background-color: var( --primary-color );
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 0;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.6);
}

.navbar {
    display: flex;
    align-items: center;
    padding: 1rem, 0;
}

.navbar .fa-bars {
    display: none;
}

.navbar img {
    width: 23rem;
    display: flex;
    justify-content: start;
    margin-right: 5.5rem;
    margin-left: 3rem;
    padding-top: .1rem;
    
}

.navbar .menu {
    display: flex;
    gap: 2rem;
    padding: 0rem 1.7rem;
    text-align: end;
    justify-content: end;
}

.navbar .menu li {
    list-style: none;
    padding: 1.7rem 0rem;
}

.navbar .menu .navlink {
    cursor: pointer;
    text-decoration: none;
    color: var(--secundary-color);
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    transition: all .4s ease;
}
.navbar .menu .navlink::after {
    content: '';
    width: 1.5rem;
    height: 2px;
    background-color: #fff;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate( -50%, 50% );
    opacity: 0;
    transition: all .4s ease;
}

.navbar .menu .navlink:hover {
    color: #fff;
}

.navbar .menu .navlink:hover::after {
    opacity: 1;
}

.navbar .menu .nav-active {
    color: #fff;
}

.navbar .menu .nav-active::after {
    content: '';
    width: 1.5rem;
    height: 2px;
    background-color: #fff;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate( -50%, 50% );
    opacity: 1;
}

.dropdown-content {
    padding-top: 2rem;
    display: none;
    position: absolute;
    background-color: var(--primary-color);
    min-width: 19rem;
    z-index: 3;
    text-align: start;
    box-shadow: 0 8px 16px rgba(0,0,0,0.3);
    z-index: 1;
    top: 6.5rem; 
}

.dropdown .dropdown-content li {
    padding: 0;
    margin: 0;
}
.dropdown-content a {
    color: var(--secundary-color);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 1.7rem;
    font-weight: 600;
    overflow: hidden;
    transition: all .3s ease;
}

.dropdown-content a:hover {
    background-color: #001531;
    overflow: hidden;
    color: #fff;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .navlink {
    color: #fff;
}

.dropdown:hover .navlink::after {
    opacity: 1;
}

.dropdown .navlink i {
    font-size: 1.3rem;
    margin-left: .2rem;
}

.navbar.container p {
    display: none;
}

.nuevo-menu.menu-active {
    display: flex;
}

.nuevo-menu {
    background-color: #001531;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    position: fixed;
    z-index: 1;
    top: 8.5rem;
}

.nuevo-menu ul {
    padding: 1rem 0rem;
    list-style: none;
}

.nuevo-menu ul li {
    list-style: none;
    padding: 1rem 0rem;
}

.nuevo-menu ul .navlink {
    cursor: pointer;
    text-decoration: none;
    color: var(--secundary-color);
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    transition: all .4s ease;
}

.nuevo-menu ul .navlink:hover {
    color: #fff;
    padding-left: 4.5px;
    color: #fff;
    border-left: 3.5px solid #fff;
}

.nuevo-menu ul .nav-active {
    padding-left: 4.5px;
    color: #fff;
    border-left: 3.5px solid #fff;
}
