
/* -------------------------------------- */
/*                 FOOTER                 */
/* -------------------------------------- */

.footer {
    background-color: var(--dark-color);
    bottom: 0;
}

.container-footer {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 4rem;
}

.menu-footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 30rem;
    gap: 3rem;
    justify-items: center;
}

.title-footer {
    font-size: 1.9rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
}

.info {
    align-items: center;
    justify-content: center;
}

.info img {
    width: 20rem;
}

.info p {
    font-size: 1.5rem;
    font-weight: 300;
    color: #ffffffdc;
}

.info,
.menufooter,
.informacion-adicional,
.contacto {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.menufooter ul,
.informacion-adicional ul,
.contacto ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.menufooter ul li .link-footer,
.informacion-adicional ul li a,
.contacto ul li {
    list-style: none;
    text-decoration: none;
    color: var(--secundary-color);
    font-size: 1.6rem;
    font-weight: 400;
}

.contacto ul li i {
    font-size: 1.7rem;
    margin-right: .5rem;
    color: #1359b6;
}

.contacto .redes-sociales {
    display: flex;
    flex-direction: initial;
    justify-content: start;
    align-items: center; 
}

.contacto .redes-sociales li {
    margin: 2rem, 3rem;
}

.contacto .redes-sociales li i {
    font-size: 2.5rem;
    cursor: pointer;
    transition: ease .3s all;
}

.contacto .redes-sociales li i:hover {
    color: var(--secundary-color);
}

.contacto .redes-sociales .facebook i {
    color: #1778f2;
}

.contacto .redes-sociales .instagram i {
    color: #f00075;
}

.contacto .redes-sociales .twiter i {
    color: #1778f2;
    
}

.contacto .redes-sociales .whatsapp i {
    color: #25d366;
}

.menufooter ul li .link-footer,
.informacion-adicional ul li a {
    cursor: pointer;
    transition: ease .3s all;
}

.menufooter ul li .link-footer:hover,
.informacion-adicional ul li a:hover {
    color: #f9f5f0;
}

.copyright {
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    padding: 1.3rem;
}

.copyright p {
    color: #f9f5f0;
    font-weight: 400;
    font-size: 1.6rem;
}

.copyright p a {
    color: #f9f5f0;
    cursor: pointer;
    text-decoration: underline;
    transition: all .3s ease;
}

.copyright p a:hover {
    color: var(--secundary-color);
}

.contacto .correo {
    list-style: none;
    text-decoration: none;
    color: var(--secundary-color);
    font-size: 1.6rem;
    font-weight: 400;
    text-decoration: none;
    transition: ease .3s all;
}

.contacto .correo:hover {
    color: #f9f5f0;
}