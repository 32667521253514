
@media ( min-width: 1425px ) {
    .mensaje-enviado {
        height: 55vh;
    }
}

@media ( max-width: 1250px ) {

    .contruccion {
        display: none;
    }


    .quienes-somos {
        display: flex;
        width: 100%;
    }

    .texto-siguiente p {
        margin: 0rem 2rem;
    }

    .quienes-somos-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 1rem 2rem;
        margin-bottom: 0px;
    }

    .quienes-somos-text img {
        display: flex;
        margin-bottom: 2rem;
        width: 32rem;
        height: auto;
        margin-right: 0px;
    }

    .quienes-somos-text p {
        text-align: center;
    }

    .texto-siguiente {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 1rem 2rem;
        margin-top: 0px;
    }

    .texto-siguiente p {
        text-align: center;
    }

    .quienes-somos ul {
        display: none;
        gap: 2rem;
    }
    
    .quienes-somos ul li {
        margin: 0rem 1rem;
        margin-top: 1rem;
    }

    .container-gallery {
        margin: 2rem;
    }

    .card-servicio {
        padding-top: 2rem;
    }

    .card-servicio {
        text-align: center;
    }

    .card-servicio .card-title::after {
        width: 16rem;
    
    }

    .card-servicio p::after {
        width: 8rem;
        margin-bottom: -.2rem;
    }

    .container-gallery {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 25rem);
    }

    .content-proyectos-realizados {
        flex-direction: column;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 0rem;
    }

    .content-proyectos-realizados.linear {
        margin-top: 0px;
        margin-bottom: 2rem;
    }


    .content-proyectos-realizados .card {
        width: 50rem;
        margin: 2rem;
        border-radius: 6px;
        overflow: hidden;
        background-color: #f9f5f0;
        box-shadow: 0px 1px 10px rgb(0, 0, 0, 0.2);
        cursor: default;
        transition: all 400ms ease;
    }

    .content-proyectos-realizados .card img {
        height: 350px;
        object-fit: cover;
    }

    .container-confiaron {
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 4rem;
    }

    .container-confiaron ul {
        width: 100%;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .container-confiaron ul li img {
        width: 15rem;
        height: auto;
    }

    .container-confiaron ul .swiper {
        margin: 0;
        padding-left: 0; 
        padding-right: 0;
        margin-top: 5rem;
    }

    .close {
        font-size: 5rem;
    }

    .next {
        font-size: 5rem;
    }

    .prev {
        font-size: 5rem;
    }

    .content-formulario {
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin: 3rem 2rem;
        margin-top: 2rem;
    }

    .formulario {
        max-width: 550px;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .formulario .input-mensaje {
        margin: 0;
        padding: 0;

    }

    .formulario-redes-sociales {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .mensaje-enviado {
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .content-mensaje {
        width: 550px;
    }

    .content-mensaje p {
        line-height: 2.5rem;
    }

    .quienes-somos-text.servicios p {
        margin-right: 0rem;
    }

    .container-banner {
        display: flex;
        height: 65rem;
        margin-top: 8rem;
    }

    .container-navbar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .navbar.container p {
        display: flex;
    }

    .container-navbar p {
        display: flex;
        margin-right: 2rem;
        margin-left: 25rem;
        justify-content: end;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        color: var(--secundary-color);
        font-size: 2.5rem;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        transition: all .4s ease;
    }

    .container-navbar img {
        margin: 0;
        margin-left: 2rem;
        margin-right: 25rem;
    }

    .container-navbar p:hover {
        color: #fff;
    }

    .container-navbar p .fa-bars {
        margin-left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-navbar .menu {
        display: none;
    }

    .contadores-principal {
        display: flex;
    }

    .quienes-somos-text img {
        width: 30rem;
        height: 25rem;
    }

    .img-agregada {
        margin: 0rem 2rem;
    }

    .img-agregada img {
        width: 100%;
        height: 30rem;
        object-fit: cover;
    }

    .content-proyectos-realizados {
        margin-top: 0;
        margin-bottom: 0;
    }

    .menu-redes .direccion {
        margin-top: -2rem;
    }
    .content-formulario {
        margin-top: 1rem;
    }

    .quienes-somos-text.servicios-page {
        margin: 1rem 2rem;
    }

    .quienes-somos-text.servicios-page img {
        width: 30rem;
        height: 20rem;
        margin-bottom: 2rem;
    }

    .quienes-somos-text.servicios .imagenes2 img {
        width: 30rem;
        height: 20rem;
        margin-bottom: 2rem;
    }
    .servicios-enlace {
        gap: 0;
    }

    .servicio-responsive {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 2rem 4rem;
    }

    .servicio-responsive p {
        font-weight: 350;
        font-size: 2rem;
        line-height: 3.5rem;
    }

    .servicio-responsive p span {
        font-weight: bold;
    }

    .quienes-somos-text.servicios p {
        display: none;
    }

    .quienes-somos-text.servico {
        margin-top: 3rem;
    }

    .quienes-somos {
        padding-top: 10rem;
        margin-top: -10rem;
    }
    .container-banner {
        margin-bottom: 3rem;
    }
    .servicios-enlace {
        margin-top: .5rem;
    }

    .container-gallery {
        padding-top: 20rem;
        margin-top: -20rem;
    }

    .dropdown-content-1 {
        display: none;
        flex-direction: column;
        list-style: none;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        z-index: 2;
    }

    .dropdown-1-active {
        display: flex;
    }

    .dropdown-content-1 li a {
        cursor: pointer;
        text-decoration: none;
        color: var(--secundary-color);
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        transition: all .4s ease;
    }

    .dropdown2 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .dropdown2 i {
        color: var(--secundary-color);
        font-size: 1.4rem;
        margin-left: 1rem;
        transition: ease all .3s;
    }

    .dropdown2 i:hover {
        color: #fff;
    }


}

@media ( max-width: 1068px ) {

    .container-footer {
        gap: 3.5rem;
        padding: 3.5rem;
    }

    .menu-footer {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .info, .menufooter, .informacion-adicional, .contacto {
        gap: 1rem;
        border-bottom: 1.5px solid #002b63 ;
        padding-bottom: 2rem;
    }

    .contacto {
        border-bottom: 0px;
        padding-bottom: 0rem;
    }

    .menu-footer .redes-sociales {
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: .5rem;
    }

    .contadores-principal {
        display: flex;
        flex-direction: column;
    }

    .contador.animar {
        margin-bottom: 1.5rem;
    }

    .contador {
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .container-soporte {
        margin: 0rem 5rem;
        margin-top: 2rem;
    }

}

@media ( max-width: 900px ) {

    .navbar .menu span {
        display: none;
    }

    .close {
        font-size: 3rem;
        top: 22%;
        z-index: 3;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
    }

    .next {
        display: none;
        top: 80rem;
        font-size: 4.5rem;
    }

    .prev {
        display: none;
        top: 80rem;
        font-size: 4.5rem;
    }

    .thumbnail-grid {
        display: grid;
        gap: 1rem;
        flex-direction: column;
        grid-template-columns: repeat( 5, 1fr );
    }

    .lightbox img {
        max-width: 90%;
        max-height: auto;
        object-fit: cover;
    }

    .deleted {
        display: flex;
        margin-top: 2.5rem;
    }

    .content-banner {
        padding-left: 5rem;
    }

    .thumbnail-grid {
        display: none;
    }

    .menu-galeria {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 1rem;
    }

    .menu-galeria p {
        font-size: 2.5rem;
        color: #fff;
        font-weight: bold;
        margin-left: 5rem;
        margin-right: 5rem;
    }

    .menu-galeria img {
        width: 7rem;
        height: 7rem;
    }

    .deleted {
        margin-top: 2rem;
        width: 6rem;
        height: 6rem;
    }

}

@media ( max-width: 860px ) {

    .quienes-somos ul {
        gap: 1rem;
    }
    
    .quienes-somos ul li {
        margin-top: 1rem;
    }

}

@media ( max-width: 800px ) {

    .quienes-somos ul {
        gap: 0rem;
    }
    
    .quienes-somos ul li {
        margin-top: 0rem;
    }

    .img-agregada img {
        height: 30rem;
    }

}

@media ( max-width: 810px ) {
    .container-navbar p {
        margin-left: 24rem;
    }

    .container-navbar img {
        margin-right: 24rem;
    }
}

@media ( max-width: 780px ) {
    .container-navbar p {
        margin-left: 23rem;
    }

    .container-navbar img {
        margin-right: 23rem;
    }
}

@media ( max-width: 760px ) {

    .quienes-somos ul .circulo1 {
        display: none;
    }

    .container-gallery {
        display: flex;
        flex-direction: column;
    }
    .lightbox {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .close {
        font-size: 3rem;
        top: 19%;
        z-index: 3;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .thumbnail-grid {
        display: grid;
        flex-direction: column;
        grid-template-columns: repeat( 4, 1fr );
    }

    .container-navbar p {
        margin-left: 20rem;
    }

    .container-navbar img {
        margin-right: 20rem;
    }

    .content-banner h1 {
        font-size: 6.3rem;
      }
      
      .content-banner p {
        font-size: 2.8rem;
      }
      
      .content-banner span .link {
        font-size: 1.3rem;
      }
      .thumbnail-grid {
        display: none;
    }

}

@media ( max-width: 700px ) {


    .container-navbar p {
        margin-left: 17rem;
    }

    .container-navbar img {
        margin-right: 17rem;
    }
    
    .content-banner h1 {
        font-size: 6.1rem;
      }
      
      .content-banner p {
        font-size: 2.6rem;
      }
      
      .content-banner span .link {
        font-size: 1.1rem;
      }

}

@media ( max-width: 650px ) {
    .container-navbar p {
        margin-left: 15rem;
    }

    .container-navbar img {
        margin-right: 15rem;
    }
    .container-soporte {
        margin: 0rem 3rem;
        margin-top: 2rem;
    }

    .content-formulario {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .formulario {
        margin-left: 0;
        margin-right: 0;
    }


}

@media ( max-width: 625px ) {

    .quienes-somos ul .circulo2 {
        display: none;
    }

    .formulario {
        max-width: 500px;
    }

    .formulario form .input-name, .input-contactos {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .formulario input {
        width: 450px;
    }

    .formulario .input-mensaje textarea {
        width: 450px;
        max-width: 450px;
        min-width: 450px;
    }

    .content-mensaje {
        width: 500px;
    }

}


@media ( max-width: 610px ) {

    .navbar .menu {
        gap: 1.5rem;
        padding: 1.5rem;
    }

    .navbar .menu .navlink {
        font-size: 1.5rem;
    }

    .container-navbar p {
        margin-left: 13rem;
    }

    .container-navbar img {
        margin-right: 13rem;
    }

    .content-banner h1 {
        font-size: 5.9rem;
      }
      
      .content-banner p {
        font-size: 2.4rem;
      }

      .img-agregada img {
        height: 25rem;
    }

}

@media ( max-width: 565px ) {

    .content-proyectos-realizados .card {
        width: 360px;
        margin: 2rem;
    }

    .content-proyectos-realizados .card img {
        height: 240px;
    }

    .container-navbar p {
        margin-left: 11rem;
    }

    .container-navbar img {
        margin-right: 11rem;
    }

}

@media ( max-width: 550px ) {

    .navbar .menu {
        gap: 1rem;
        padding: 1.5rem;
    }

    .navbar .menu .navlink {
        font-size: 1.4rem;
    }

    .formulario {
        max-width: 450px;
    }

    .formulario input {
        width: 400px;
    }

    .formulario .input-mensaje textarea {
        width: 400px;
        max-width: 400px;
        min-width: 400px;
    }

    .content-mensaje {
        width: 450px;
    }


    .content-banner h1 {
        font-size: 5.7rem;
      }
      
      .content-banner p {
        font-size: 2.2rem;
      }

}

@media ( max-width: 520px ) {
    .container-navbar p {
        margin-left: 9rem;
    }

    .container-navbar img {
        margin-right: 9rem;
    }

    .content-banner h1 {
        font-size: 5.5rem;
      }
      
      .content-banner p {
        font-size: 2rem;
      }
} 

@media ( max-width: 510px ) {

    .navbar .menu {
        gap: 1rem;
        padding: 1.3rem;
    }

    .navbar .menu .navlink {
        font-size: 1.3rem;
    }


    .formulario {
        max-width: 400px;
    }

    .formulario input {
        width: 350px;
    }

    .formulario .input-mensaje textarea {
        width: 350px;
        max-width: 350px;
        min-width: 350px;
        height: 150px;
        max-height: 150px;
        min-height: 150px;
    }

    .formulario-redes-sociales {
        max-width: 400px;
    }

   .menu-redes span {
        display: flex;
        flex-direction: column;
        margin-top: .5rem;
        margin-bottom: 1rem;
   }

   .menu-redes p {
        display: flex;
        flex-direction: column;
        margin-bottom: .5rem;
   }

   .content-mensaje {
        width: 400px;
    }

    .content-banner h1 {
        font-size: 5.3rem;
      }
      
      .content-banner p {
        font-size: 1.8rem;
      }

      .content-banner {
        padding-left: 3rem;
      }

      .img-agregada img {
        height: 20rem;
    }

}

@media ( max-width: 490px ) {

    .quienes-somos ul .circulo3 {
        display: none;
    }

    .thumbnail-grid {
        grid-template-columns: repeat( 4, 1fr );
    }
    .close {
        font-size: 3rem;
        top: 19.9%;
        z-index: 3;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .container-navbar p {
        margin-left: 8rem;
    }

    .container-navbar img {
        margin-right: 8rem;
    }

}

@media ( max-width: 475px ) {

    .copyright p {
        font-size: 1.4rem;
    }

    .content-banner h1 {
        font-size: 5.1rem;
      }


}

@media ( max-width: 450px ) {

    .navbar .menu {
        padding: 1.2rem;
    }

    .navbar .menu .navlink {
        font-size: 1.2rem;
    }

    .close {
        font-size: 3rem;
        top: 20%;
        z-index: 3;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .formulario {
        max-width: 350px;
    }

    .formulario input {
        width: 300px;
    }

    .formulario .input-mensaje textarea {
        width: 300px;
        max-width: 300px;
        min-width: 300px;
    }

    .formulario-redes-sociales {
        max-width: 350px;
    }

    .content-mensaje {
        width: 350px;
    }

    .content-banner h1 {
        font-size: 4.9rem;
      }


}

@media ( max-width: 460px ) {
    .container-navbar p {
        margin-left: 6rem;
    }

    .container-navbar img {
        margin-right: 6rem;
    }
}

@media ( max-width: 430px ) {

    .navbar .menu .navlink {
        font-size: 1.1rem;
    }
    .content-banner h1 {
        font-size: 4.7rem;
      }
}

@media ( max-width: 420px ) {
    .container-navbar p {
        margin-left: 4rem;
    }

    .container-navbar img {
        margin-right: 4rem;
    }
}

@media ( max-width: 410px ) {

    .copyright p {
        font-size: 1.2rem;
    }

    .close {
        font-size: 5rem;
        top: 22rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .content-mensaje {
        width: 300px;
    }

    .content-banner h1 {
        font-size: 4.5rem;
      }
      .img-agregada img {
        height: 15rem;
    }
}

@media ( max-width: 395px ) {

    .navbar .menu .navlink {
        font-size: 1rem;
    }

    .close {
        font-size: 5rem;
        top: 23rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .formulario {
        max-width: 300px;
    }

    .formulario input {
        width: 250px;
    }

    .formulario .input-mensaje textarea {
        width: 250px;
        max-width: 250px;
        min-width: 250px;
        height: 150px;
        max-height: 150px;
        min-height: 150px;
    }
    .formulario-redes-sociales {
        max-width: 300px;
    }

    .content-banner h1 {
        font-size: 4.3rem;
      }

}

@media ( max-width: 370px ) {
    .container-navbar p {
        margin-left: 2rem;
    }

    .container-navbar img {
        margin-right: 2rem;
    }

    .content-banner h1 {
        font-size: 4rem;
      } 
      .content-banner p {
        font-size: 1.2rem;
      }
}


@media ( max-width: 370px ) {

    .navbar .menu .navlink {
        font-size: .9rem;
    }

    .copyright p {
        font-size: 1rem;
    }

    .quienes-somos ul li img {
        width: 8.5rem;
    }

    .close {
        font-size: 5rem;
        top: 24rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }

}

@media ( max-width: 340px ) {

    .content-banner h1 {
        font-size: 2rem;
    }

    .close {
        font-size: 5rem;
        top: 25rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }

}

@media ( max-width: 335px ) {

    .navbar .menu {
        padding: .9rem;
    }

    .navbar .menu .navlink {
        font-size: .8rem;
    }

    .container-navbar p {
        margin-left: 0rem;
    }

    .container-navbar img {
        margin-right: 0rem;
    }

}

/* -------------------------------------- */
/*         MEDIA QUERIES --1068PX          */
/* -------------------------------------- */

/*@media ( max-width: 1068px ) {

    .sitio-contruccuion {
        display: none;
    }
    
    .socials {
        display: none;
    }

    .sitio-contruccuion img {
        width: 100%;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .container-footer {
        gap: 3.5rem;
        padding: 3.5rem;
    }

    .menu-footer {
        grid-template-columns: repeat( 1, 1fr ) 30rem;
        gap: 2.5rem;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .info, .menufooter, .informacion-adicional, .contacto {
        gap: 1rem;
    }
    
    .navbar .menu li span {
        display: none;
    }

    .info img {
        width: 18rem;
    }

    .contacto .redes-sociales {
        justify-content: center;
        text-align: center;
        align-items: center; 
    }
    
}




/* -------------------------------------- */
/*         MEDIA QUERIES --768PX          */
/* -------------------------------------- */
/*


    .quienes-somos ul li img {
        width: 7rem;
        border: 3px solid #1359b6;
    }
    .container-gallery {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 25rem);
    }

    .gallery-img-5, .gallery-img-6 {
        grid-column: span 2;
    }
    .content-proyectos-realizados {
        flex-direction: column;
        align-items: stretch;
    }
    .content-proyectos-realizados .card {
        width: 50%;
        margin: 1rem auto;
    }
    .container-confiaron ul .swiper {
        margin: 4rem 6rem;
    }

    .container-confiaron ul li img {
        width: 12rem;
        height: 12rem;
    }
    .content-formulario {
        grid-template-columns: 1fr 1fr;
        margin: 3rem 5rem;
    }

    .formulario input {
        font-size: 1.8rem;
    }

    .formulario textarea {
        font-size: 1.8rem;
    }


}



@media (max-width: 480px) {

    .content-banner h1 {
        font-size: 2.5rem;
    }

    .container-banner p {
        font-size: 1.2rem;
    }

    .container-banner span .link {
        padding: 0.6rem 1.5rem;
    }
    .contador_cantidad {
        font-size: 2.5rem;
    }
    
    .contador h2 {
        font-size: 1.5rem;
    }
    .quienes-somos ul li {
        margin: 1rem;
    }

    .quienes-somos ul li img {
        width: 6rem;
        border: 2px solid #1359b6;
    }
    .container-gallery {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(6, 20rem);
    }

    .gallery-img-5, .gallery-img-6 {
        grid-column: span 1;
    }

    .card-servicio .card-title {
        font-size: 2rem;
    }

    .card-servicio .textServicio {
        font-size: 1.2rem;
        padding: 0 1rem;
    }

    .card-servicio .ver-mas {
        font-size: 1rem;
        padding: 0.5rem 2rem;
    }
    .content-proyectos-realizados .card {
        width: 100%;
        margin: 1rem 0;
    }

    .content-proyectos-realizados .card .contenido p {
        font-size: 1.2rem;
    }

    .content-proyectos-realizados .card .contenido h3 {
        font-size: 1.5rem;
    }

    .content-proyectos-realizados .card .contenido a {
        font-size: 1.1rem;
        padding: 0.8rem 1.5rem;
    }
    .container-confiaron ul .swiper {
        margin: 3rem 4rem;
    }

    .container-confiaron ul li img {
        width: 10rem;
        height: 10rem;
    }
    .content-formulario {
        grid-template-columns: 1fr;
        margin: 2rem 3rem;
    }

    .formulario input {
        font-size: 1.6rem;
    }

    .formulario textarea {
        font-size: 1.6rem;
    }
}

/* -------------------------------------- */
/*         MEDIA QUERIES --468PX          */
/* -------------------------------------- */

/*@media ( max-width: 468px ) {

    html {
        font-size: 42.5%;
    }

    .sitio-contruccuion {
        margin: 1rem, 1rem;
        height: 40rem;
    }
    
    .sitio-contruccuion img {
        width: 70%;
    }

    .container-header {
        height: 20rem;
    }

    .container-header img {
        width: 24rem;
    }

    .container-banner {
        height: auto;
        padding: 5rem 1rem;
    }

    .content-banner {
        padding: 5rem 1rem;
    }

    .content-banner h1 {
        font-size: 2.5rem;
    }

    .container-banner p {
        font-size: 1.2rem;
    }

    .container-banner span {
        padding: 0.8rem 2rem;
        font-size: 1.2rem;
    }


} */