
/* -------------------------------------- */
/*                SOCIALS                 */
/* -------------------------------------- */

.socials {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    bottom: 15px;
    right: 15px;
    position: fixed;
    z-index: 1;
    transition: all .4s ease;
}

.socials i {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    font-size: 3rem;
    color: #f9f5f0;
    background-color: #1359b6;
    border: 3px solid #f9f5f0;
    border-radius: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    overflow: hidden;
    transition: ease .4s all;
}

.socials i:hover {
    background-color: #f9f5f0;
    border: 3px solid #1359b6;
    color: #1359b6;
}

.socials-main {
    display: none;
}

.socials-main.active {
    display: flex;
    z-index: -1;
    bottom: 0rem;
    position: relative;
}

.socials-main.active .fa-facebook {
    bottom: 0rem;
    position: relative;
    animation-name: mianimacion4;
    animation-duration: .9s;
}

.socials-main.active .fa-instagram {
    background-color: #f00075;
    bottom: 0rem;
    position: relative;
    animation-name: mianimacion3;
    animation-duration: .7s;
}

.socials-main.active .fa-linkedin{
    bottom: 0rem;
    position: relative;
    animation-name: mianimacion2;
    animation-duration: .5s;
}

.socials-main.active .fa-whatsapp {
    background-color: #25d366;
    bottom: 0rem;
    position: relative;
    animation-name: mianimacion1;
    animation-duration: .3s;
}

@keyframes mianimacion1 {
    0% { bottom: -6rem; }
}

@keyframes mianimacion2 {
    0% { bottom: -11rem; }
}

@keyframes mianimacion3 {
    0% { bottom: -18rem; }
}

@keyframes mianimacion4 {
    0% { bottom: -23rem; }
}

.socials-main.no-active {
    display: flex;
    z-index: -1;
    bottom: 0rem;
    position: relative;
}

.socials-main.no-active .fa-facebook {
    bottom: -24rem;
    position: relative;
    animation-name: mianimacion8;
    animation-duration: .8s;
}

.socials-main.no-active .fa-instagram {
    background-color: #f00075;
    bottom: -19rem;
    position: relative;
    animation-name: mianimacion7;
    animation-duration: 1s;
}

.socials-main.no-active .fa-linkedin{
    bottom: -13rem;
    position: relative;
    animation-name: mianimacion6;
    animation-duration: 1.2s;
}

.socials-main.no-active .fa-whatsapp {
    background-color: #25d366;
    bottom: -7rem;
    position: relative;
    animation-name: mianimacion5;
    animation-duration: 1.4s;
}

@keyframes mianimacion5 {
    0% { bottom: 0rem; }
}

@keyframes mianimacion6 {
    0% { bottom: 0rem; }
}

@keyframes mianimacion7 {
    0% { bottom: 0rem; }
}

@keyframes mianimacion8 {
    0% { bottom: 0rem; }
}

.socials i:hover.fa-instagram {
    background-color: #f9f5f0;
    border: 3px solid #1359b6;
    color: #1359b6;
}

.socials i:hover.fa-whatsapp {
    background-color: #f9f5f0;
    border: 3px solid #1359b6;
    color: #1359b6;
}

.socials i:hover.fa-linkedin {
    background-color: #f9f5f0;
    border: 3px solid #1359b6;
    color: #1359b6;
}


.socials-main ul {
    display: flex;
    flex-direction: column;
}

.socials-main ul li {
    list-style: none;
    margin-bottom: .5rem;
    margin-top: .5rem;
}

.socials-main ul li i {
    font-size: 2rem;
    padding: 1rem;
}