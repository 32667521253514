
/* -------------------------------------- */
/*              CONSTRUCTION              */
/* -------------------------------------- */

.sitio-contruccuion {
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin: 5rem, 5rem;
    width: 100%;
    height: 60rem;
}

.sitio-contruccuion img {
    width: 60%;
}